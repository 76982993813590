import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMovies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/movie', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMovie(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/movie/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createMovie(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/movie`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMovieDetails(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/movie/${id}?with_staff=1&themes=1`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMovieSequels(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/movie/${id}/sequels`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loadMovieFromKP(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/movie/import-from-kp/${id}?with_staff=1&themes=1`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loadMovieFromKPDev(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/movie/import-from-kp/${id}?with_staff=1&themes=1&api=kp-dev`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loadStaffFromKP(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/movie/load-staff/${id}?with_staff=1&themes=1`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMovieWithStaff(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/movie/${id}?with_staff=1`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFrames(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/movie/${id}/frames`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMoviesPremiers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/movie/premiers', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMoviesTop(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/movie/top', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMoviesFilter(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/movie/filter', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchMovies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/movie/search', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePerson(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/person/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
