import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchQuestionsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/question', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchQuestion(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/question/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addQuestion(ctx, {question}) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/question/create', question)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveQuestion(ctx, {id, question}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/question/${id}`, {question})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteQuestion(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/question/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        upload(ctx, file) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('file', file);
                axios
                    .post('/upload', formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
